
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        




































.days {
  background: $gray-200;
  padding: rem(4) 0 rem(4) rem(4);
  overflow-y: auto;

  .btn {
    margin-right: rem(4);

    &:hover,
    &:focus {
      background: $white;
    }

    &.active {
      background: $primary;
      color: $white;
    }
  }
}
